<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col md="12" lg="12" class="d-flex justify-content-between flex-column">
        <!-- User Avatar & Action Buttons -->

        <div class="d-flex justify-content-start">
          <b-avatar :src="userData?.avatar" size="104px" rounded />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-1">
                {{ userData?.name }}
              </h4>

              <span class="card-text">{{ userData?.employee_number }}</span>
              <br />
              <span class="card-text">{{ userData?.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <template
                v-if="
                  $permissionAbility(USERS_PROFILE_DETAILS_ACCESS, permissions)
                "
              >
                <b-button
                  :to="{
                    name: 'admin-user-edit-details',
                    params: { id: this.$route.params.id },
                  }"
                  variant="primary"
                >
                  Show Profile Details
                </b-button></template
              >
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col md="12" lg="12" class="mt-5">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="HashIcon" class="mr-75" />
              <span class="font-weight-bold">Employee ID</span>
            </th>
            <td class="pb-50">
              {{ userData?.employee_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="HashIcon" class="mr-75" />
              <span class="font-weight-bold">Machine Employee ID</span>
            </th>
            <td class="pb-50">
              {{ userData?.machine_user_id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="BoxIcon" class="mr-75" />
              <span class="font-weight-bold">Work Type</span>
            </th>
            <td class="pb-50">
              {{ userData?.work_type }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
              {{ userData?.name }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="MailIcon" class="mr-75" />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ userData?.email }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PhoneIcon" class="mr-75" />
              <span class="font-weight-bold">Contact Number</span>
            </th>
            <td class="pb-50">
              {{ userData?.mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <template v-if="userData?.status">
                <b-badge variant="success" class="badge-glow"> Active </b-badge>
              </template>
              <template v-else-if="!userData?.status">
                <b-badge variant="danger" class="badge-glow">
                  Inactive
                </b-badge>
              </template>
            </td>
          </tr>
          <tr v-if="userData?.terminated">
            <th  class="pb-50">
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">Termination Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <template>
                <b-badge :variant="userData?.termination_type === 'terminated' ? 'danger' : 'warning' " class="badge-glow">
                  {{ userData?.termination_type === 'terminated'
                    ? "Terminated"
                    : "Contract Ended" }}
                </b-badge>
              </template>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="BoxIcon" class="mr-75" />
              <span class="font-weight-bold">Division</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData?.department?.data?.division?.data?.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="PackageIcon" class="mr-75" />
              <span class="font-weight-bold">Department</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData?.department?.data?.name }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon icon="AwardIcon" class="mr-75" />
              <span class="font-weight-bold">Designation</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData?.designation?.data?.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserCheckIcon" class="mr-75" />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData?.roles?.data[0]?.name }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

  <script>
import { USERS_PROFILE_DETAILS_ACCESS } from "@/helpers/permissionsConstant";
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import UserAdditionalDetails from "./UserAdditionalDetails.vue";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "UserInfoCard",
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    UserAdditionalDetails,
    BBadge,
  },
  data() {
    return {
      USERS_PROFILE_DETAILS_ACCESS,
      userData: {},
    };
  },
  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      const [userGeneralData] = await Promise.all([this.getUserGeneralData()]);

      this.userData = userGeneralData?.data?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },
  methods: {
    async getUserGeneralData() {
      return await this.$api.get(
        `api/users/${this.$route.params.id}?include=department.division,roles,employeeType,designation,leaveTypes`
      );
    },
  },
};
</script>

  <style lang="scss">
</style>
